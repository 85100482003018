import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { auth, db } from './firebase';
import { onAuthStateChanged } from "firebase/auth";
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import store from './store'; // Import the Vuex store from the same directory

// Font Awesome imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { 
  faCheckCircle, faEnvelope, faGripLines, faPalette, faLink, faClock, 
  faUsersLine, faCaretDown, faGear, faThumbsUp, faThumbsDown,
  faCirclePlus, faFileImport, faMicrochip, faCloudArrowDown,
  faMicrochipAi, faSparkles, faTag, faArrowUpRightFromSquare, faFileArrowUp,
  faUpload, faArrowProgress, faGears, faBolt, faBoltLightning, faFilePdf,
  faFileArrowDown, faUsers, faPlus, faTrash, faHeart, faTrashAlt, faSave,
  faMedal, faFire, faCertificate, faCircleStar, faAward, faSeal, faSealExclamation,
  faTrophy, faAlbumCollection, faCompactDisc, faSquareQuestion, faQuestion,
  faCircleQuestion, faAnchor, faPaperPlane, faCaretRight
} from '@fortawesome/pro-solid-svg-icons'; // Change this to pro-solid-svg-icons
import { 
  faThumbsUp as farThumbsUp, 
  faThumbsDown as farThumbsDown,
  faCircleCheck as farCircleCheck,
  faSparkles as farSparkles,
  faFileImport as farFileImport,
  faClock as farClock,
  faPlus as farPlus,
  faTrash as farTrash,
  faHeart as farHeart,
} from '@fortawesome/pro-regular-svg-icons'; // Change this to pro-regular-svg-icons
import { faInstagram, faTiktok, faTwitter, faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';

// Add icons to the library
library.add(
  faCheckCircle, faEnvelope, faGripLines, faInstagram, faTiktok, faTwitter, 
  faSpotify, faPalette, faLink, faClock, faUsersLine, faCaretDown, faGear, 
  faThumbsUp, faThumbsDown, farThumbsUp, farThumbsDown, farCircleCheck, faYoutube,
  faCirclePlus, faFileImport, faMicrochip, faCloudArrowDown, faMicrochipAi, farSparkles,
  faSparkles, farFileImport, faTag, faArrowUpRightFromSquare, faFileArrowUp, faUpload,
  faArrowProgress, faGears, faBolt, faBoltLightning, faFilePdf, faFileArrowDown, farClock,
  faUsers, faPlus, faTrash, farPlus, farTrash, faHeart, farHeart, faTrashAlt,
  faSave, faMedal, faFire, faCertificate, faCircleStar, faAward, faSeal, faSealExclamation,
  faTrophy, faAlbumCollection, faCompactDisc, faSquareQuestion, faQuestion,
  faCircleQuestion, faAnchor, faPaperPlane, faCaretRight
);

let app;

// Track page views
router.beforeEach((to, from, next) => {
  logEvent(analytics, 'page_view', { 
    page_title: to.name,
    page_path: to.path
  });
  next();
});

onAuthStateChanged(auth, async (user) => {
  if (!app) {
    app = createApp(App);
    app.component('font-awesome-icon', FontAwesomeIcon); // Register Font Awesome component
    app.use(router);
    app.use(store); // Use the Vuex store
    app.mount('#app');
  }

  if (user) {
    console.log("User is signed in:", user);
    // Set user properties for analytics
    logEvent(analytics, 'login', { method: user.providerData[0].providerId });

    // Check user's email verification and onboarding status
    if (!user.emailVerified) {
      router.push('/verify-email');
    } else {
      const userDoc = await getDoc(doc(db, "profiles", user.uid));
      const userData = userDoc.data();
      if (!userData || !userData.onboardingCompleted) {
        router.push('/welcome');
      } else if (router.currentRoute.value.path === '/login' || router.currentRoute.value.path === '/signup') {
        router.push('/feed');
      }
    }
  } else {
    console.log("No user is signed in.");
    // Optionally, redirect to login page if user is on a protected route
    const currentRoute = router.currentRoute.value;
    if (currentRoute.meta.requiresAuth) {
      router.push('/login');
    }
  }
});

export default app;