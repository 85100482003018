<template>
  <div id="app">
    <nav class="main-nav" v-if="isNavReady">
      <div class="nav-container">
        <router-link to="/" class="nav-logo">
          <img src="/logo.png" alt="3urn Logo" class="logo-image">
        </router-link>
        <div class="hamburger" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div :class="['nav-links', { 'active': isMenuOpen }]">

          <div v-if="isAdmin" class="dropdown">
            <button class="dropbtn">Admin 
              <font-awesome-icon icon="caret-down" />
            </button>
            <div class="dropdown-content">
              <router-link to="/admin/ai" class="dropdown-link" @click="closeMenu">AI Engine</router-link>
              <router-link to="/admin/distributors" class="dropdown-link" @click="closeMenu">Manage Distributors</router-link>
              <router-link to="/admin/recommendations" class="dropdown-link" @click="closeMenu">Recommendations</router-link>
              <router-link to="/admin/publicity" class="dropdown-link" @click="closeMenu">Rec. Publicity</router-link>
              <router-link to="/admin/users" class="dropdown-link" @click="closeMenu">Users</router-link>
              <router-link to="/release-contributors" class="dropdown-link" @click="closeMenu">Release Contributors</router-link>
            </div>
          </div>

          <div v-if="showDistributorMenu" class="dropdown">
            <button class="dropbtn">Distributor 
              <font-awesome-icon icon="caret-down" />
            </button>
            <div class="dropdown-content">
              <router-link to="/distributor/labels" class="dropdown-link" @click="closeMenu">Manage Labels</router-link>
            </div>
          </div>

          <div v-if="showLabelMenu" class="dropdown">
            <button class="dropbtn">Label 
              <font-awesome-icon icon="caret-down" />
            </button>
            <div class="dropdown-content">
              <router-link to="/label-admin" class="dropdown-link" @click="closeMenu">Artist Roster</router-link>
              <router-link to="/manage-profiles" class="dropdown-link" @click="closeMenu">Profiles</router-link>
              <router-link to="/label-releases" class="dropdown-link" @click="closeMenu">Products</router-link>
              <router-link to="/label-publicity" class="dropdown-link" @click="closeMenu">Publicity</router-link>
            </div>
          </div>

          <router-link v-if="!isAuthenticated" to="/signup" class="nav-link" @click="closeMenu">Sign Up</router-link>
          
          <button v-if="!isAuthenticated" @click="login" class="login-btn">Login</button>

          <router-link v-if="isAuthenticated && isOnboarded" to="/feed" class="nav-link" @click="closeMenu">Feed</router-link>

          <router-link v-if="isAuthenticated && isOnboarded" to="/collection" class="nav-link" @click="closeMenu">Collection</router-link>

          <div v-if="isAuthenticated && isOnboarded && showManageMenu" class="dropdown">
            <button class="dropbtn">Manage 
              <font-awesome-icon icon="caret-down" />
            </button>
            <div class="dropdown-content">
              <router-link to="/releases" class="dropdown-link" @click="closeMenu">Releases</router-link>
              <router-link to="/publicity" class="dropdown-link" @click="closeMenu">Publicity</router-link>
              <router-link to="/tracks" class="dropdown-link" @click="closeMenu">Tracks</router-link>
              <router-link to="/events" class="dropdown-link" @click="closeMenu">Events</router-link>
              <router-link to="/photos" class="dropdown-link" @click="closeMenu">Photos</router-link>
            </div>
          </div>

          <router-link v-if="isAuthenticated && isOnboarded" to="/profile" class="nav-link" @click="closeMenu">My Profile</router-link>

          <router-link v-if="isAuthenticated && isOnboarded" to="/help" class="nav-link" @click="closeMenu">
            <font-awesome-icon :icon="['fas', 'circle-question']" />
          </router-link>

          <button v-if="isAuthenticated" @click="logout" class="logout-btn">Logout</button>

        </div>
      </div>
    </nav>
    <div v-else class="nav-placeholder"></div>
    <main class="main-content">
      <div class="section">
        <router-view @onboarding-completed="handleOnboardingCompleted" />
      </div>
    </main>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from './firebase';
import { doc, getDoc, onSnapshot, query, collection, where, getDocs } from 'firebase/firestore';

export default {
  name: 'App',
  setup() {
    const router = useRouter();
    const isAuthenticated = ref(false);
    const isMenuOpen = ref(false);
    const isAdmin = ref(false);
    const isDistributor = ref(false);
    const isLabel = ref(false);
    const isIndustry = ref(false);
    const isNavReady = ref(false);
    const isOnboarded = ref(false);
    let profileUnsubscribe = null;
    const currentUser = ref(null);
    const userDistributorId = ref(null);
    const userLabelIds = ref([]);
    const isIndustryAssociatedWithDistributor = ref(false);
    const labels = ref([]); // Add this line to define the labels ref

    const checkAdminStatus = async (user) => {
      if (!user) return false;
      try {
        const adminListDoc = await getDoc(doc(db, 'adminConfig', 'adminList'));
        if (adminListDoc.exists()) {
          const adminEmails = adminListDoc.data().emails || [];
          return adminEmails.includes(user.email);
        }
        return false;
      } catch (error) {
        console.error("Error checking admin status:", error);
        return false;
      }
    };

    const findUserLabelsOrDistributor = async (userId) => {
      const labelQuery = query(
        collection(db, 'profiles'),
        where('accountType', '==', 'label'),
        where('industryTeam', 'array-contains', userId)
      );
      const adminLabelQuery = query(
        collection(db, 'profiles'),
        where('accountType', '==', 'label'),
        where('industryAdmin', 'array-contains', userId)
      );
      const distributorQuery = query(
        collection(db, 'profiles'),
        where('accountType', '==', 'distributor'),
        where('industryTeam', 'array-contains', userId)
      );

      const [labelSnapshot, adminLabelSnapshot, distributorSnapshot] = await Promise.all([
        getDocs(labelQuery),
        getDocs(adminLabelQuery),
        getDocs(distributorQuery)
      ]);

      const allLabels = [...labelSnapshot.docs, ...adminLabelSnapshot.docs];
      const uniqueLabels = allLabels.filter((label, index, self) =>
        index === self.findIndex((t) => t.id === label.id)
      );

      if (uniqueLabels.length > 0) {
        labels.value = uniqueLabels.map(doc => ({ id: doc.id, ...doc.data() }));
        userLabelIds.value = labels.value.map(label => label.id);
      }

      if (distributorSnapshot.docs.length > 0) {
        isIndustryAssociatedWithDistributor.value = true;
        userDistributorId.value = distributorSnapshot.docs[0].id;
      } else {
        isIndustryAssociatedWithDistributor.value = false;
        userDistributorId.value = null;
      }
    };

    const updateUserStatus = async (user) => {
      if (user) {
        isAuthenticated.value = true;
        currentUser.value = user;
        isAdmin.value = await checkAdminStatus(user);
        const userDoc = await getDoc(doc(db, 'profiles', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          isDistributor.value = userData.accountType === 'distributor';
          isLabel.value = userData.accountType === 'label';
          isIndustry.value = userData.accountType === 'industry';
          if (isIndustry.value) {
            await findUserLabelsOrDistributor(user.uid);
          }
        }
        await checkOnboardingStatus(user);
      } else {
        isAuthenticated.value = false;
        currentUser.value = null;
        isAdmin.value = false;
        isDistributor.value = false;
        isLabel.value = false;
        isIndustry.value = false;
        userDistributorId.value = null;
        userLabelIds.value = [];
        isIndustryAssociatedWithDistributor.value = false;
        isOnboarded.value = false;
      }
      isNavReady.value = true;
    };

    const showDistributorMenu = computed(() => {
      return isAdmin.value || isDistributor.value || (isIndustry.value && userDistributorId.value);
    });

    const showLabelMenu = computed(() => {
      return isAdmin.value || isLabel.value || (isIndustry.value && (userLabelIds.value.length > 0 || isIndustryAssociatedWithDistributor.value));
    });

    const showManageMenu = computed(() => {
      return isAdmin.value || 
             (isAuthenticated.value && !isDistributor.value && !isLabel.value && !isIndustry.value) ||
             (isIndustry.value && !isIndustryAssociatedWithDistributor.value && userLabelIds.value.length === 0);
    });

    const checkOnboardingStatus = async (user) => {
      if (!user) {
        isOnboarded.value = false;
        return;
      }
      try {
        if (profileUnsubscribe) {
          profileUnsubscribe();
        }
        profileUnsubscribe = onSnapshot(doc(db, 'profiles', user.uid), (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            isOnboarded.value = userData.onboardingCompleted || false;
            if (!isOnboarded.value && router.currentRoute.value.path !== '/welcome') {
              router.push('/welcome');
            }
          } else {
            isOnboarded.value = false;
          }
        });
      } catch (error) {
        console.error("Error checking onboarding status:", error);
        isOnboarded.value = false;
      }
    };

    const setViewportHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const handleResize = () => {
      setViewportHeight();
    };

    const login = () => {
      router.push('/login');
    };

    const logout = async () => {
      try {
        await auth.signOut();
        isAuthenticated.value = false;
        isAdmin.value = false;
        isDistributor.value = false;
        isLabel.value = false;
        isIndustry.value = false;
        isOnboarded.value = false;
        userDistributorId.value = null;
        userLabelIds.value = [];
        router.push('/');
      } catch (error) {
        console.error("Logout failed:", error);
      }
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const closeMenu = () => {
      isMenuOpen.value = false;
    };

    onMounted(() => {
      setViewportHeight();
      window.addEventListener('resize', handleResize);

      auth.onAuthStateChanged(async user => {
        await updateUserStatus(user);
        if (user && !user.emailVerified) {
          router.push('/verify-email');
        }
      });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
      if (profileUnsubscribe) {
        profileUnsubscribe();
      }
    });

    watch(isOnboarded, async (newValue) => {
      if (newValue && currentUser.value) {
        await updateUserStatus(currentUser.value);
      }
    });

    return {
      isAuthenticated,
      isMenuOpen,
      isAdmin,
      showDistributorMenu,
      showLabelMenu,
      isNavReady,
      isOnboarded,
      logout,
      toggleMenu,
      closeMenu,
      login,
      showManageMenu,
      handleOnboardingCompleted: () => {
        isOnboarded.value = true;
        router.push('/feed');
      }
    };
  }
};
</script>

<style>
:root {
  --vh: 1vh;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-family: Arial, sans-serif;
}

.main-nav {
  background-color: #333;
  color: white;
  padding: 5px 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.nav-container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 8px 12px;
  margin: 0 3px;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-size: 0.9em;
}

.nav-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  height: 30px;
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link:hover {
  background-color: #555;
}

.login-btn, .logout-btn {
  background-color: #FF6600;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 8px;
}

.login-btn:hover {
  background-color: #F09440;
}

.logout-btn {
  background-color: #f44336;
}

.logout-btn:hover {
  background-color: #d32f2f;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #333;
  color: white;
  padding: 8px 12px;
  font-size: 0.9em;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-link {
  color: black;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
  font-size: 0.9em;
}

.dropdown-link:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-placeholder {
  height: 40px;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.section {
  min-height: 100%;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 22px;
  height: 2px;
  background-color: white;
  margin-bottom: 4px;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .nav-container {
    flex-wrap: wrap;
  }

  .hamburger {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: none;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-link, .logout-btn, .dropdown {
    margin: 3px 0;
    width: 100%;
  }

  .dropdown-content {
    position: static;
    display: none;
    width: 100%;
    box-shadow: none;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .logo-image {
    height: 25px;
  }
}
</style>