import { createRouter, createWebHistory } from 'vue-router';
import { getAuth } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { analytics, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomePage.vue')
  },
  {
    path: '/feed',
    name: 'Feed',
    component: () => import(/* webpackChunkName: "feed" */ '../components/UserFeed.vue'),
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import(/* webpackChunkName: "preview" */ '../components/UserFeed.vue'),
    props: { isPreview: true }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signup" */ '../components/SignUp.vue')
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import(/* webpackChunkName: "login" */ '../components/LoginPage.vue')
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import(/* webpackChunkName: "verify-email" */ '../components/VerifyEmail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/welcome',
    name: 'UserWelcome',
    component: () => import(/* webpackChunkName: "user-welcome" */ '../components/UserWelcome.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "profile" */ '../components/ProfileManager.vue'),
    props: { isOwnProfile: true },
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/tracks',
    name: 'TrackManager',
    component: () => import(/* webpackChunkName: "tracks" */ '../components/TrackManager.vue'),
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/events',
    name: 'EventManager',
    component: () => import(/* webpackChunkName: "events" */ '../components/EventManager.vue'),
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/photos',
    name: 'PhotoManager',
    component: () => import(/* webpackChunkName: "photos" */ '../components/PhotoManager.vue'),
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/releases',
    name: 'ReleaseManager',
    component: () => import(/* webpackChunkName: "releases" */ '../components/LabelReleases.vue'),
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/publicity',
    name: 'PublicityManager',
    component: () => import(/* webpackChunkName: "publicity" */ '../components/LabelPublicity.vue'),
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/collection',
    name: 'UserCollection',
    component: () => import(/* webpackChunkName: "collection" */ '../components/UserCollection.vue'),
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/help',
    name: 'HelpCenter',
    component: () => import(/* webpackChunkName: "help" */ '../components/HelpCenter.vue'),
    meta: { requiresAuth: true, requiresOnboarding: true }
  },
  {
    path: '/admin/ai',
    name: 'AdminAI',
    component: () => import(/* webpackChunkName: "adminai" */ '../components/AdminAI.vue'),
    meta: { requiresAuth: true, requiresAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/admin/recommendations',
    name: 'AdminRec',
    component: () => import(/* webpackChunkName: "adminrec" */ '../components/AdminRec.vue'),
    meta: { requiresAuth: true, requiresAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/admin/publicity',
    name: 'AdminPub',
    component: () => import(/* webpackChunkName: "adminpub" */ '../components/AdminPub.vue'),
    meta: { requiresAuth: true, requiresAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/admin/users',
    name: 'AdminUser',
    component: () => import(/* webpackChunkName: "adminuser" */ '../components/AdminUser.vue'),
    meta: { requiresAuth: true, requiresAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/admin/distributors',
    name: 'AdminUser',
    component: () => import(/* webpackChunkName: "admindist" */ '../components/TopAdmin.vue'),
    meta: { requiresAuth: true, requiresAdmin: true, requiresOnboarding: true }
  },  {
    path: '/:username',
    name: 'PublicProfile',
    component: () => import(/* webpackChunkName: "publicprofile" */ '../components/PublicProfile.vue')
  },
  {
    path: '/label-admin',
    name: 'LabelAdmin',
    component: () => import(/* webpackChunkName: "label-admin" */ '../components/LabelAdmin.vue'),
    meta: { requiresAuth: true, requiresLabelOrAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/manage-profiles',
    name: 'ManageProfiles',
    component: () => import(/* webpackChunkName: "manage-profiles" */ '../components/ProfileManager.vue'),
    props: { isAdminMode: true, isOwnProfile: false },
    meta: { requiresAuth: true, requiresLabelOrAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/label-publicity',
    name: 'LabelPublicity',
    component: () => import(/* webpackChunkName: "label-publicity" */ '../components/LabelPublicity.vue'),
    meta: { requiresAuth: true, requiresLabelOrAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/label-releases',
    name: 'LabelReleases',
    component: () => import(/* webpackChunkName: "label-releases" */ '../components/LabelReleases.vue'),
    meta: { requiresAuth: true, requiresLabelOrAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/planner',
    name: 'ReleasePlanner',
    component: () => import(/* webpackChunkName: "release-planner" */ '../components/ReleasePlanner.vue')
  },
  {
    path: '/release-contributors',
    name: 'ReleaseContributors',
    component: () => import(/* webpackChunkName: "release-contributors" */ '../components/ReleaseContributors.vue'),
    meta: { requiresAuth: true, requiresLabelOrAdmin: true, requiresOnboarding: true }
  },
  // New distributor routes
  {
    path: '/distributor/labels',
    name: 'DistributorLabels',
    component: () => import(/* webpackChunkName: "label-admin" */ '../components/DistributorAdmin.vue'),
    meta: { requiresAuth: true, requiresDistributorOrAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/distributor/artists',
    name: 'DistributorArtists',
    component: () => import(/* webpackChunkName: "manage-profiles" */ '../components/ProfileManager.vue'),
    props: { isAdminMode: true, isOwnProfile: false },
    meta: { requiresAuth: true, requiresDistributorOrAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/distributor/releases',
    name: 'DistributorReleases',
    component: () => import(/* webpackChunkName: "label-releases" */ '../components/LabelReleases.vue'),
    meta: { requiresAuth: true, requiresDistributorOrAdmin: true, requiresOnboarding: true }
  },
  {
    path: '/distributor/publicity',
    name: 'DistributorPublicity',
    component: () => import(/* webpackChunkName: "label-publicity" */ '../components/LabelPublicity.vue'),
    meta: { requiresAuth: true, requiresDistributorOrAdmin: true, requiresOnboarding: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

async function checkAdminStatus(user) {
  if (!user) return false;
  try {
    const adminListDoc = await getDoc(doc(db, 'adminConfig', 'adminList'));
    if (adminListDoc.exists()) {
      const adminEmails = adminListDoc.data().emails || [];
      return adminEmails.includes(user.email);
    }
    return false;
  } catch (error) {
    console.error("Error checking admin status:", error);
    return false;
  }
}

async function checkAccountType(user) {
  if (!user) return { isDistributor: false, isLabel: false, isIndustry: false };
  try {
    const profileDoc = await getDoc(doc(db, 'profiles', user.uid));
    if (profileDoc.exists()) {
      const profileData = profileDoc.data();
      return {
        isDistributor: profileData.accountType === 'distributor',
        isLabel: profileData.accountType === 'label',
        isIndustry: profileData.accountType === 'industry'
      };
    }
    return { isDistributor: false, isLabel: false, isIndustry: false };
  } catch (error) {
    console.error("Error checking account type:", error);
    return { isDistributor: false, isLabel: false, isIndustry: false };
  }
}

router.beforeEach(async (to, from, next) => {
  logEvent(analytics, 'page_view', { page_path: to.path });

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const requiresDistributorOrAdmin = to.matched.some(record => record.meta.requiresDistributorOrAdmin);
  const requiresLabelOrAdmin = to.matched.some(record => record.meta.requiresLabelOrAdmin);
  const requiresOnboarding = to.matched.some(record => record.meta.requiresOnboarding);
  const requiresArtist = to.matched.some(record => record.meta.requiresArtist);
  
  const auth = getAuth();
  const user = auth.currentUser;

  if (requiresAuth && !user) {
    next('/login');
    return;
  }

  if (user) {
    await user.reload();
    
    const userDocRef = doc(db, 'profiles', user.uid);
    const docSnapshot = await getDoc(userDocRef);
    const userData = docSnapshot.data();

    if (!user.emailVerified && to.path !== '/verify-email') {
      next('/verify-email');
      return;
    }

    if (user.emailVerified && !userData?.onboardingCompleted && to.path !== '/welcome') {
      next('/welcome');
      return;
    }

    const isAdmin = await checkAdminStatus(user);
    const { isDistributor, isLabel, isIndustry } = await checkAccountType(user);

    if (requiresAdmin && !isAdmin) {
      next('/');
      return;
    }

    if (requiresDistributorOrAdmin && !(isDistributor || isAdmin || isIndustry)) {
      next('/');
      return;
    }

    if (requiresLabelOrAdmin && !(isLabel || isAdmin || isIndustry)) {
      next('/');
      return;
    }

    if (requiresArtist && userData.accountType !== 'artist') {
      next('/');
      return;
    }

    if (requiresOnboarding && !userData?.onboardingCompleted) {
      next('/welcome');
      return;
    }
  }

  next();
});

export function handleOnboardingCompleted(router) {
  router.push('/feed');
}

export default router;