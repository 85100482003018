import { createStore } from 'vuex';

export default createStore({
  state: {
    selectedArtistId: null,
  },
  mutations: {
    setSelectedArtistId(state, id) {
      state.selectedArtistId = id;
    },
  },
  actions: {
    updateSelectedArtistId({ commit }, id) {
      commit('setSelectedArtistId', id);
    },
  },
  getters: {
    getSelectedArtistId: (state) => state.selectedArtistId,
  },
});