// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics, isSupported } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDMyL17XoSUJRou5SU-XeJmBv6QYx5qj3I",
  authDomain: "burn-app-428202.firebaseapp.com",
  projectId: "burn-app-428202",
  storageBucket: "burn-app-428202.appspot.com",
  messagingSenderId: "166248231102",
  appId: "1:166248231102:web:3ddee252afa1734fb7eeef",
  measurementId: "G-VLQ4EB7EKT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Initialize analytics only if supported
let analytics = null;
isSupported().then(supported => {
  if (supported) {
    analytics = getAnalytics(app);
  }
}).catch(error => {
  console.warn("Analytics not initialized due to: ", error);
});

export { app, analytics, auth, db, storage, functions };